<template src="./Announcement.html"></template>

<script>
import categoryService from "@/services/Category";
import idiService from "@/services/Idi";
import announcementService from "../../services/Announcement";
import newsService from "../../services/News";
import { putFile } from "@/services/files";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import { required } from "vuelidate/lib/validators";

export default {
  name: "announcement",
  components: {
    ModalDescriptionFile,
  },
  data() {
    return {
      title: "Crear convocatoria",
      titleDetail: "Detalle convocatoria",
      announcement: {
        fileImg: "",
        fileCriteriaAcceptance: "",
        publicState: "internal",
        name: "",
        description: "",
        amount: "",
        category: [],
        institution: [],
        dateInit: "",
        dateFinish: "",
      },
      categories: [],
      institutionList: [],
      modalAnnouncementCreated: false,
      createAndEdit: true,
      update: false,
      requirements: {
        criteriaOfAcceptance: [
          "Tamaño del caracter 11",
          "Formato del documento: PDF",
        ],
        avalInstitutional: {
          description: [
            "Carta de la institución educativa a la que pertenece el grupo de investigación",
          ],
          maximumPages: "Maximo páginas: 1",
        },

        firstSummary: {
          summary: [
            "Describa los principales puntos que se abordarán en la propuesta: Objetivos, metodología y resultados esperados.",
          ],
          maximumPages: "Maximo páginas: 1",
        },
        bibliographicReferences: {
          description: [],
          maximumPages: "Maximo páginas: 2",
        },
        requestedResources: {
          description: [
            "Tener en cuenta los siguientes recursos a justificar: Personal a contratar, tanto técnico como académico, equipos, materiales administrativos y pasantías nacionales e internacionales.",
          ],
          maximumPages: "Maximo páginas: 1",
        },
        formulationProposal: {
          proposal: [
            "En este item especifique los siguiente: fundamentos teóricos y estado del arte, objetivos e Hipótesis o preguntas de investigación, novedad científica o tecnológica de la propuesta, metodología, plan de trabajo, cronograma y cuadro Gantt",
          ],
          maximumPages: "Maximo páginas: 10",
        },
        availableResources: {
          description: [
            "En esta sección se incluyen todos los recursos de equipamiento, infraestructura y de colaboración que disponen en la institución para ejecutar adecuadamente la propuesta.",
          ],
          maximumPages: "Maximo páginas: 1",
        },
        principalInvestigator: {
          description: [
            "Describa la experiencia del investigador principal y la formación académica.",
          ],
          maximumPages: "Maximo páginas: 1",
        },
      },
      event: "",
      showModalImageDescriptionJpg: false,
      showModalImageDescriptionPdf: false,
      infoImage: "",
      infoPdf: "",
    };
  },
  validations: {
    announcement: {
      name: { required },
      description: { required },
      amount: { required },
      category: { required },
      institution: { required },
      fileImg: { required },
      fileCriteriaAcceptance: { required },
      dateInit: { required },
      dateFinish: { required },
      publicState: { required },
    },
  },
  created() {
    this.getInitData();
    if (this.$route.params.id) {
      this.getInfoEditAnnouncement();
    }
  },
  methods: {
    async getInitData() {
      try {
        this.categories = await categoryService.getCategories();
        this.institutionList = await idiService.listInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: IDI/getInitData()", e);
      }
    },
    async getInfoEditAnnouncement() {
      try {
        this.infoEditAnnouncement =
          await announcementService.getSpecificAnnouncement(
            this.$route.params.id
          );
        this.update = true;
        this.announcement.name = this.infoEditAnnouncement.announcement.name;
        this.announcement.description =
          this.infoEditAnnouncement.announcement.description;
        this.announcement.amount =
          this.infoEditAnnouncement.announcement.amountWin;
        this.announcement.category =
          this.infoEditAnnouncement.announcement.category.id;
        this.announcement.institution =
          this.infoEditAnnouncement.announcement.institution.id;
        this.announcement.fileImg =
          this.infoEditAnnouncement.announcement.fileImg;
        this.announcement.fileCriteriaAcceptance =
          this.infoEditAnnouncement.announcement.fileCriteriaAcceptance;
        this.announcement.dateInit = new Date(
          this.infoEditAnnouncement.announcement.dateInit
        );
        this.announcement.dateFinish = new Date(
          this.infoEditAnnouncement.announcement.dateFinish
        );
        this.announcement.publicState =
          this.infoEditAnnouncement.announcement.publicState;
        this.announcement.descriptionImg =
          this.infoEditAnnouncement.announcement.descriptionImg;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/getInfo()", e);
      }
    },
    showModalAnnouncementCreated() {
      this.modalAnnouncementCreated = true;
    },
    async saveAnnouncement(publicNews) {
      this.$store.dispatch("fetchLoading", true);
      let urlImage = null;
      if (this.infoImage) {
        try {
          urlImage = await putFile(
            this.infoImage,
            "announcement",
            `${this.infoImage.name}`
          );
          this.announcement.imageUrl = urlImage;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando la imagen",
            position: "is-bottom",
            type: "is-danger",
          });
          this.modalAnnouncementCreated = false;
          return null;
        }
      }
      let urlFile = null;
      if (this.infoPdf) {
        try {
          urlFile = await putFile(
            this.infoPdf,
            "announcement",
            `${this.infoPdf.name}`
          );
          this.announcement.fileUrl = urlFile;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
          this.modalAnnouncementCreated = false;
          return null;
        }
      }
      try {
        const obj = {
          name: this.announcement.name,
          description: this.announcement.description,
          amountWin: Number(this.announcement.amount),
          category: this.announcement.category,
          institution: this.announcement.institution,
          fileImg: this.announcement.imageUrl,
          descriptionImg: this.announcement.descriptionImg,
          fileCriteriaAcceptance: this.announcement.fileUrl,
          descriptionFileCriteriaAcceptance:
            this.announcement.descriptionFileCriteriaAcceptance,
          dateInit: this.announcement.dateInit,
          dateFinish: this.announcement.dateFinish,
          publicState: this.announcement.publicState,
          token: this.$store.state.User.token,
        };
        let infoAnnouncementCreated = null;
        if (this.$route.params.id) {
          delete obj.token;
          infoAnnouncementCreated =
            await announcementService.updateAnnouncement(
              this.$route.params.id,
              obj
            );
        } else {
          infoAnnouncementCreated =
            await announcementService.createAnnouncement(obj);
        }
        if (!infoAnnouncementCreated) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Se presentó un error",
            position: "is-bottom",
            type: "is-danger",
          });
          return null;
        }
        this.modalAnnouncementCreated = false;
        this.$store.dispatch("fetchLoading", false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Convocatoria creada",
          position: "is-bottom",
          type: "is-success",
        });
        if (publicNews) {
          await this.postInNews();
        } else {
          this.$router.push("/verTodasLasConvocatoriasAbiertas");
        }
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        this.modalAnnouncementCreated = false;
        console.error("Error: blogArticles/saveNews()", e);
        return null;
      }
    },
    async postInNews() {
      this.$store.dispatch("fetchLoading", true);
      try {
        const obj = {
          name: this.announcement.name,
          description: this.announcement.description,
          image: this.announcement.imageUrl,
          descriptionImg: this.announcement.descriptionImg,
          institutionId: this.announcement.institution,
          categoryId: this.announcement.category,
        };
        await newsService.createNews(obj);
        this.modalAnnouncementCreated = false;
        this.$store.dispatch("fetchLoading", false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Noticia creada",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({
          name: "ViewAllNews",
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        this.modalAnnouncementCreated = false;
        console.error("Error: blogArticles/saveNews()", e);
      }
    },
    editAnnouncement() {
      this.createAndEdit = true;
      this.update = true;
    },
    deleteImage() {
      this.announcement.fileImg = null;
    },
    deleteFile() {
      this.announcement.file = null;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    openModalImageDescriptionJpg() {
      this.showModalImageDescriptionJpg = true;
    },
    openModalImageDescriptionPdf() {
      this.showModalImageDescriptionPdf = true;
    },
    closeModalDescriptionImageJpg() {
      this.showModalImageDescriptionJpg = false;
    },
    closeModalDescriptionImagePdf() {
      this.showModalImageDescriptionPdf = false;
    },
    uploadFileInfoJpg(info) {
      this.announcement.descriptionImg = info.description;
      this.infoImage = info.file;
      this.announcement.fileImg = this.infoImage;
      this.showModalImageDescriptionJpg = false;
    },
    uploadFileInfoPdf(info) {
      this.announcement.descriptionFileCriteriaAcceptance = info.description;
      this.infoPdf = info.file;
      this.announcement.fileCriteriaAcceptance = this.infoPdf;
      this.showModalImageDescriptionPdf = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./Announcement.scss">
</style>